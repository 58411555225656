<template>
  <div class="w1092">
    <h2 class="giveName">{{$t('front.give.title1')}}</h2>
    <div class="giveInput">
      <ul class="datesearch">
        <li>
          <!--input type="text" name="id" list="id" :placeholder="$t('front.give.bottomPatner')" v-model="selectMemId">
          <datalist id="id" >
            <template v-for="member in memList" v-bind:key="member.memId">
              <option :value="member.memId">{{member.memNick}}</option>
            </template>
          </datalist-->
          <select v-model="selectPartnerCode">
            <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
            <template v-for="item in partnerLevelList" v-bind:key="item.code">
              <option :value="item.code">{{item.codeName}}</option>
            </template>
          </select>
        </li>
        <li>
          <!--input type="text" name="id" list="id" :placeholder="$t('front.give.resultIDs')" v-model="selectMemId">
          <datalist id="id" >
            <template v-for="member in memList" v-bind:key="member.memId">
              <option :value="member.memId">{{member.memNick}}</option>
            </template>
          </datalist-->
          <select v-model="selectMem">
            <option value="" disabled selected>{{$t('front.give.resultIDs')}}</option>
            <template v-for="member in myMemIdsList" v-bind:key="member.memId">
              <option :value="member">{{member.memNick}}</option>
            </template>
          </select>
        </li>
        <li>{{$t('front.give.moneys')}}
          <input type="text" :placeholder="$t('front.give.moneyInput')" v-model="cashAmt"/>
          <input type="text" :placeholder="$t('front.give.targetMoney')" v-model="selectMem.cashAmt" readonly />
        </li>
        <li><a class="bgg" @click="memCash('-3')">{{$t('front.give.give')}}</a></li>
        <li><a class="bgh" @click="memCash('3')">{{$t('front.give.back')}}</a></li>
      </ul>
    </div>
    <h2 class="giveName">{{$t('front.give.title2')}}</h2>
    <div class="giveSearch">
      <div class="datesearch">
        <p>{{$t('front.give.date')}}:
          <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
          <span>~</span>
          <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
        </p>
        <p>
          받은 아이디:<input type="text" v-model="searchForm.botMemId"/>
        </p>
        <p>
          {{$t('front.give.sucess')}}:
          <select v-model="searchForm.cashStatus">
            <option :value="''">{{$t('front.gameCategory.all')}}</option>
            <option :value="'1'">{{$t('front.give.ok')}}</option>
            <option :value="'-1'">{{$t('front.give.fail')}}</option>
          </select>
        </p>
        <a class="bgf" @click="loadData">{{$t('front.give.search')}}</a>
        <p>
          <select v-model="searchForm.cashType">
            <option :value="''">{{$t('front.gameCategory.all')}}</option>
            <option :value="'-3'">{{$t('front.give.give')}}</option>
            <option :value="'3'">{{$t('front.give.back')}}</option>
          </select>
        </p>
      </div>
    </div>

    <div class="board" v-if="list">
      <ul class="boardw first">
        <li>idx</li>
        <li>{{$t('front.give.backId')}}</li>
        <li>{{$t('front.common.nickName')}}</li>
        <li>{{$t('front.search.content')}}</li>
        <li>{{$t('front.give.sendMoney')}}</li>
        <li>{{$t('front.give.endCash')}}</li>
        <li>{{$t('front.give.haveCash')}}</li>
        <li>{{$t('front.give.sendTime')}}</li>
        <li>{{$t('front.give.yesorno')}}</li>
        <li>{{$t('front.give.etc')}}</li>
      </ul>
      <template v-if="list.length > 0">

        <template v-for="(item, index) in list" v-bind:key="item.cashIdx">
          <ul class="boardw">
            <li>{{index+1}}</li>
            <li>{{item.botMemId}}</li>
            <li>{{item.botMemNick}}</li>
            <li class="blc" v-if="item.cashType === '-3'">{{$t('front.give.give')}}</li>
            <li class="rdc" v-else>회수</li>
            <li :class="{'rdc': item.cashType ==='3', 'blc':item.cashType ==='-3'}">{{thousand(item.cashAmt)}}</li>
            <li>{{thousand(item.aftCashAmt)}}</li>
            <li>{{thousand(item.preCashAmt)}}</li>
            <li>{{dateFormatAll(item.regDate)}}</li>
            <li class="blc" v-if="item.cashStatus === '1'">O</li>
            <li class="rdc" v-else>X</li>
            <li>{{item.memo}}</li>
          </ul>
        </template>
      </template>
      <template v-else>
        <ul class="boardw">
          {{$t('front.give.give')}}/{{$t('front.give.back')}} {{$t('front.common.notFoundList')}}
        </ul>
      </template>

    </div>

    <pagination :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                @goToPage="loadData"/>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getCashSendList, getMemList, memCashInOut } from '@/api/give'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { partnerLevels, partnerMyMemIds } from '@/api/retail'

export default {
  name: 'Give',
  components: { Pagination },
  data () {
    return {
      model: {},
      memList: [],
      selectMemId: '',
      selectMem: {
        cashAmt: ''
      },
      cashAmt: '',
      list: null,
      searchForm: {
        cashType: '',
        cashStatus: '',
        botMemId: '',
        startDate: getDateStr(getSubDaysDate(new Date(), 7)),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      partnerLevelList: [],
      selectPartnerCode: '',
      myMemIdsList: [],
      isProcessing: false
    }
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
    }
  },
  created () {
    this.getPartnerLevels()
    this.loadData()
    this.getMemList()
  },
  methods: {
    thousand,
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
        }
      })
    },
    onChangeStartDate (event) {
      const startDate = event.target.value
      this.searchForm.startDate = startDate
    },
    onChangeEndDate (event) {
      const endDate = event.target.value
      this.searchForm.endDate = endDate
    },
    loadData (page) {
      if (!page) page = this.pageInfo.page
      const form = JSON.parse(JSON.stringify(this.searchForm))
      form.startDate += ' 00:00:00'
      form.endDate += ' 23:59:59'
      // form.page = page

      console.log(form)
      getCashSendList(form).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          console.log(result)
          this.list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    async memCash (type) {
      if (!this.cashAmt) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!this.selectMem.memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        this.isProcessing = true

        const params = {
          memId: this.selectMem.memId
        }
        if (type === '-3') {
          params.inAmt = this.cashAmt
        } else {
          params.outAmt = this.cashAmt
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: this.selectMem.memNick, cashAmt: this.cashAmt, type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          memCashInOut(params, type).then(async response => {
            const result = response.data
            console.log(result)
            if (result.resultCode === '0') {
              if (type === '-3') {
                this.onCheck('지급 완료')
              } else {
                this.onCheck('회수 완료')
              }
              this.selectMem = null
              this.cashAmt = ''
              this.loadData()
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    },
    getMemList () {
      getMemList({}).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          this.memList = result.data.memIds
        }
      })
    }
  }
}
</script>
<style scoped>
.boardpage {
  margin-top: 30px;
}

.giveName {
  font-size: 16px;
  margin-bottom: 18px;
  font-weight: bold;
  color: #fff;
}

.giveInput {
   border-radius: 3px;
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #ef6621;
   background-color: #1b1a20;
  padding: 19px 0;
  margin-bottom: 65px;
}

.giveInput ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0;
}

.giveInput li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.giveSearch p {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.giveSearch .chk {
  gap: 3px;
}

.giveSearch .chk input {
  width: auto !important;
  height: auto !important;
  margin: 0 0 0 5px;
}
select option[value=""][disabled] {
   display: none;
}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
