import httpClient from '@/libs/http-client'

export function getMemList (params) {
  const url = '/api/retail/myMemIds'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function memCashInOut (params, type) {
  let url = '/api/retail/cashIn'
  if (type === '3') {
    url = '/api/retail/cashOut'
  }
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getCashSendList (params) {
  const url = '/api/retail/cashSendlist'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
